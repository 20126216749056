<template>
  <ws-dialog
      v-model="display"
      :title="title"
      @save="addLead"
  >

    <!-- CONTENT-->

    <ws-text-field
        v-model="entityData.name"
        :label="$t('HumanName')"
        :placeholder="$t('EnterHumanName')"
    />
    <ws-phone-input
        class="mt-5"
        v-model="entityData.phone"
        :label="$t('Phone') "
    />

    <ws-text-field
        v-model="entityData.email"
        :label="$t('Email')"
        :placeholder="$t('EnterEmail')"
    />

    <ws-text-field
        class="mt-5"
        v-model="entityData.comment"
        :label="$t('Comment')"
        :placeholder="$t('EnterComment')"
        area
    />



  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "vmodelComponent",
  props : {
    value : {
      type : Boolean
    },
    title : {
      type : String
    },
    origin : {
      type : String,
      default : 'contact_form'
    }
  },
  data() {
    return {
      display : false,
      entityData : {}
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('leads', ['ADD_BUSINESS_LEAD']),

    async addLead() {
      if ( !this.entityData.phone ) {
        return this.notify(this.$t('PhoneNumberEmpty'))
      }
      if ( !this.entityData.name ) {
        return this.notify(this.$t('NameEmpty'))
      }
      this.entityData.origin = this.origin
      this.entityData.device = this.DEVICE
      let result = await this.ADD_BUSINESS_LEAD(this.entityData)
      if( !result ) { return }
      this.notify(this.$t('BusinessLeadCreated'))
      this.entityData = {}
      this.display = false
    },
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>